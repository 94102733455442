import React from 'react';

import { Section } from '../../atoms/Section';
import { SocialLinks } from '../../molecules/SocialLinks';
import { Bio } from '../../organisms/Bio';

import { section, name, bio } from './Intro.data';

export const Intro = () => (
  <Section>
    <Bio />
    <SocialLinks />
  </Section>
);
