/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Intro } from '../components/containers/Intro';
import { PageLayout } from '../components/containers/PageLayout';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const IndexPage = () => {
  const { title: defaultTitle, description: defaultDescription } = useSiteMetadata();

  return (
    <main>
      <Helmet>
        <title>{defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
      </Helmet>
      <PageLayout>
        <Intro />
      </PageLayout>
    </main>
  );
};

export default IndexPage;
