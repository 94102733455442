import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 160px;

  h2 {
    font-size: 1.17em;
  }

  svg {
    height: 48px;
    width: 48px;
    path {
      fill: var(--color-text);
    }
  }
`;

export const HeaderThemeSwitcher = styled.button`
  background: transparent;
  border: 0;
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  justify-content: center;
  padding: 4px;
  cursor: pointer;

  svg {
    height: 24px;
    width: 24px;
    fill: var(--color-text);
    transition: 0.3s ease;
    transform: scaleX(-1) rotate(-25deg);
  }

  &:hover {
    svg {
      transform: scaleX(-1) rotate(15deg);
    }
  }
`;

export const HeaderLogoLabel = styled.p`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  flex-direction: column;
`;
