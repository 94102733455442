import React, { ReactNode } from 'react';

import { Page } from '../../organisms/Page';

import { navigation, logo } from './PageLayout.data';

type PageLayoutProps = {
  children: ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => <Page navLinks={navigation}>{children}</Page>;
