import React from 'react';

import { BsPaintBucket } from 'react-icons/bs';
import LogoSvg from '../../../images/logo.inline.svg';
import { Navigation } from '../../molecules/Navigation';

import { NavLinks } from '../../../types';

import { Header, HeaderLogo, HeaderThemeSwitcher } from './Header.styles';
import { useTheme } from '../../../context/themeContext';

type HeaderComponentProps = {
  navLinks: NavLinks;
};

export const HeaderComponent = ({ navLinks }: HeaderComponentProps) => {
  const { isDarkTheme, switchThemeCallback } = useTheme();

  return (
    <Header>
      {/* <Navigation navLinks={navLinks} /> */}
      <HeaderLogo>
        {/* <LogoSvg /> */}
        <h2>Catherine McDonough</h2>
      </HeaderLogo>

      <HeaderThemeSwitcher type="button" onClick={() => switchThemeCallback(isDarkTheme)}>
        <BsPaintBucket />
      </HeaderThemeSwitcher>
    </Header>
  );
};
