import styled, { css, keyframes } from 'styled-components';

import PaintDripPinkSvg from '../../../images/paint-drip-pink.svg';
import PaintDripDarkBlueSvg from '../../../images/paint-drip-dark-blue.svg';

const animateBackground = keyframes`
  0% {
    height: 0px;
  }

  12% {
    height: 0px;
  }

  75% {
    height: 100%;
    opacity: 1;
  }

  100% {
    height: 100%;
    opacity: 0;
  }
`;

const animateDrip = keyframes`
  0% {
    transform: translateY(-350px);
  }

  20% {
    transform: translateY(0px);
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const PaintDripBackground = styled.span`
  display: block;
  width: 100vw;
`;

const backgroundImage = (bgColor: any) =>
  css`
    background-image: url(${bgColor === 'dark' ? PaintDripPinkSvg : PaintDripDarkBlueSvg});
  `;

export const PaintDripSvg = styled.span<{ bgColor: 'dark' | 'light' | '' }>`
  height: 0;
  width: 100%;
  background-repeat: repeat-x;
  background-size: contain;
  /* background-position: center; */
  display: block;
  opacity: 0;
`;

export const PaintDrip = styled.span<{ isActive: boolean; bgColor: 'dark' | 'light' | '' }>`
  height: 0;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  width: 100vw;
  opacity: 0;
  background: transparent;

  ${PaintDripSvg} {
    ${(props) => backgroundImage(props.bgColor)}
  }

  ${PaintDripBackground} {
    background: ${(props) => (props.bgColor === 'dark' ? 'var(--color-pink)' : 'var(--color-dark-blue)')};
  }

  ${(props) =>
    props.isActive &&
    css`
      height: 100vh;
      opacity: 1;

      ${PaintDripSvg} {
        opacity: 1;
        height: 350px;
        animation: ${animateDrip} 1.2s ease-in;
      }

      ${PaintDripBackground} {
        height: 0px;
        animation: ${animateBackground} 1.2s ease-in;
      }
    `}
`;
