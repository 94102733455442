import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 48px);
  height: calc(100vh - 36px);
  max-width: 1000px;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-bottom: 75px;
`;
