import React, { ReactNode } from 'react';
import { NavLinks } from '../../../types';
import { PaintDrip } from '../../atoms/PaintDrip';

import { Footer } from '../../molecules/Footer';
import { Header } from '../Header';

import { Page, ContentWrapper } from './Page.styles';

type PageComponentProps = {
  children: ReactNode;
  navLinks: NavLinks;
};

export const PageComponent = ({ children, navLinks }: PageComponentProps) => (
  <>
    <PaintDrip />
    <Page>
      <Header navLinks={navLinks} />
      <ContentWrapper>{children}</ContentWrapper>
      <Footer />
    </Page>
  </>
);
