import React from 'react';

import { SocialLinks, SocialLink } from './SocialLink.styles';

const data = [
  {
    label: 'Email',
    link: 'mailto:hello@catherine.dev',
  },
  {
    label: 'GitHub',
    link: 'https://github.com/catherine-mcdonough',
  },
  {
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/in/catherine-mcdonough-67b400b2/',
  },
  {
    label: 'Twitter',
    link: 'https://twitter.com/_catherinemc',
  },
];

export const SocialLinksComponent = () => (
  <SocialLinks>
    {data.map(({ label, link }) => (
      <SocialLink>
        <a href={link}>{label}</a>
      </SocialLink>
    ))}
  </SocialLinks>
);
