import React, { ReactNode } from 'react';

import { Section, SectionTitle } from './Section.styles';

type SectionComponentProps = {
  children: ReactNode;
  id?: string;
  title?: string;
};

export const SectionComponent = ({ children, id, title }: SectionComponentProps) => (
  <Section>
    {title && <SectionTitle>{title}</SectionTitle>}
    {children}
  </Section>
);
