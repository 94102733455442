import React, { useEffect } from 'react';

import { useTheme } from '../../../context/themeContext';

import { PaintDrip, PaintDripBackground, PaintDripSvg } from './PaintDrip.styles';

export const PaintDripComponent = () => {
  const { switchThemeIsActive, currentBgColor } = useTheme();

  useEffect(() => {
    if (switchThemeIsActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [switchThemeIsActive]);

  return (
    <PaintDrip isActive={switchThemeIsActive} bgColor={currentBgColor}>
      <PaintDripBackground />
      <PaintDripSvg bgColor={currentBgColor} />
    </PaintDrip>
  );
};
