import React from 'react';

import { Bio } from './Bio.styles';

export const BioComponent = () => (
  <Bio>
    <h1>I build digital products with seamless user interfaces.</h1>
    <p>
      I'm a Manchester-based frontend developer, (and occassional designer), with 8 years of experience working in
      multi-disciplinary digital teams.
    </p>
    <p>I am currently helping to build web applications at Victorian Plumbing.</p>
  </Bio>
);
