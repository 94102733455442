import styled from 'styled-components';

export const SocialLinks = styled.ul`
  margin: 40px 0 0 0;
  padding: 0;
  font-size: var(--font-size-sm);
  font-weight: bold;
  letter-spacing: 0.4px;
  list-style: none;
  display: flex;
  gap: 4px;
`;

export const SocialLink = styled.li`
  display: flex;
  gap: 4px;

  a {
    color: inherit;
    text-decoration: none;
    padding: 0 2px;
    position: relative;

    &:after,
    &:before {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      height: 2px;
      background-color: var(--color-text);
    }

    &:after {
      opacity: 0;
      transform: translateY(6.5px);
      transition: opacity 0.2s, -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s;
      transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.2s,
        -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:before {
      opacity: 0;
      transform: translateY(-13px);
      transition: opacity 0s, -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s;
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0s,
        -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:focus:after,
    &:focus:before,
    &:hover:after,
    &:hover:before {
      opacity: 1;
      transform: translateY(0);
    }

    &:focus:before,
    &:hover:before {
      transition: opacity 0s 0.2s, -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s, opacity 0s 0.2s;
      transition: transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s, opacity 0s 0.2s,
        -webkit-transform 0s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
    }
  }

  &:after {
    content: '/';
    display: block;
  }

  &:last-child:after {
    display: none;
  }
`;
