export const navigation = [
  {
    link: 'about',
    label: 'About',
  },
  {
    link: 'experience',
    label: 'Experience',
  },
  {
    link: 'contact',
    label: 'Contact',
  },
];

export const logo = {
  imagePath: '',
  link: '/',
};
